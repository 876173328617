import { template as template_f413e302b67949bbaad836281e3a3a11 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_f413e302b67949bbaad836281e3a3a11(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_f413e302b67949bbaad836281e3a3a11(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_f413e302b67949bbaad836281e3a3a11(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
