import { template as template_538760c3e681434b95e049a9dc4ec67e } from "@ember/template-compiler";
const FKLabel = template_538760c3e681434b95e049a9dc4ec67e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
