import { template as template_8af96bd1fd104a7dbafdb3aab007e0c4 } from "@ember/template-compiler";
const FKText = template_8af96bd1fd104a7dbafdb3aab007e0c4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
