import { template as template_d9e007c111e146c38f0c3a42e2f128e0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9e007c111e146c38f0c3a42e2f128e0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
