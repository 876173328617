import { template as template_3eb49b46beaa494d974e53edf8c1c062 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_3eb49b46beaa494d974e53edf8c1c062(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
